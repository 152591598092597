import styled from 'styled-components';

const StyledIconDiv = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 0px;
    pointer-events: none;
`;

export default { StyledIconDiv };
