import React from 'react';

const Metamask = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="43"
            height="40"
            fill="none"
            viewBox="0 0 54 50"
        >
            <path
                fill="url(#paint0_linear_11808_11417)"
                d="M51.03.333L30.195 15.75l3.875-9.085L51.03.333z"
            ></path>
            <path
                fill="url(#paint1_linear_11808_11417)"
                d="M51.03.333L30.195 15.75l3.875-9.085L51.03.333z"
            ></path>
            <path
                fill="url(#paint2_linear_11808_11417)"
                d="M2.97.333l20.65 15.56-3.689-9.228L2.971.333z"
            ></path>
            <path
                fill="url(#paint3_linear_11808_11417)"
                d="M2.97.333l20.65 15.56-3.689-9.228L2.971.333z"
            ></path>
            <path
                fill="url(#paint4_linear_11808_11417)"
                d="M43.529 36.079l-5.544 8.468 11.87 3.269 3.401-11.552-9.727-.185z"
            ></path>
            <path
                fill="url(#paint5_linear_11808_11417)"
                d="M43.529 36.079l-5.544 8.468 11.87 3.269 3.401-11.552-9.727-.185z"
            ></path>
            <path
                fill="url(#paint6_linear_11808_11417)"
                d="M.767 36.264l3.38 11.552 11.85-3.269-5.524-8.468-9.706.185z"
            ></path>
            <path
                fill="url(#paint7_linear_11808_11417)"
                d="M.767 36.264l3.38 11.552 11.85-3.269-5.524-8.468-9.706.185z"
            ></path>
            <path
                fill="url(#paint8_linear_11808_11417)"
                d="M15.357 21.773l-3.297 4.974 11.746.535-.391-12.642-8.058 7.133z"
            ></path>
            <path
                fill="url(#paint9_linear_11808_11417)"
                d="M15.357 21.773l-3.297 4.974 11.746.535-.391-12.642-8.058 7.133z"
            ></path>
            <path
                fill="url(#paint10_linear_11808_11417)"
                d="M38.645 21.773l-8.182-7.277-.268 12.786 11.747-.535-3.297-4.974z"
            ></path>
            <path
                fill="#E27625"
                d="M15.996 44.548l7.11-3.433-6.12-4.769-.99 8.202zM30.895 41.115l7.089 3.433-.969-8.202-6.12 4.769z"
            ></path>
            <path
                fill="url(#paint11_linear_11808_11417)"
                d="M37.984 44.547l-7.09-3.432.578 4.604-.062 1.953 6.574-3.125z"
            ></path>
            <path
                fill="url(#paint12_radial_11808_11417)"
                d="M15.996 44.547l6.595 3.125-.042-1.953.557-4.604-7.11 3.432z"
            ></path>
            <path
                fill="url(#paint13_linear_11808_11417)"
                d="M22.714 33.303l-5.894-1.726 4.163-1.912 1.731 3.638z"
            ></path>
            <path
                fill="url(#paint14_linear_11808_11417)"
                d="M31.29 33.303l1.73-3.638 4.184 1.912-5.915 1.726z"
            ></path>
            <path
                fill="#CC6228"
                d="M15.996 44.547l1.03-8.468-6.553.185 5.523 8.283zM36.974 36.079l1.01 8.468 5.543-8.283-6.553-.185zM41.942 26.747l-11.747.534 1.093 6.023 1.73-3.639 4.184 1.912 4.74-4.83zM16.82 31.577l4.163-1.912 1.731 3.639 1.092-6.023-11.746-.534 4.76 4.83z"
            ></path>
            <path
                fill="#E27525"
                d="M12.06 26.747l4.925 9.599-.165-4.769-4.76-4.83zM37.202 31.577l-.185 4.769 4.925-9.6-4.74 4.831zM23.807 27.281l-1.092 6.023 1.38 7.112.31-9.373-.598-3.762zM30.192 27.281l-.577 3.742.289 9.393 1.38-7.112-1.092-6.023z"
            ></path>
            <path
                fill="url(#paint15_linear_11808_11417)"
                d="M31.285 33.304l-1.38 7.112.988.699 6.121-4.769.186-4.769-5.915 1.727z"
            ></path>
            <path
                fill="url(#paint16_linear_11808_11417)"
                d="M16.82 31.577l.165 4.769 6.12 4.769.99-.7-1.38-7.111-5.895-1.727z"
            ></path>
            <path
                fill="#C0AC9D"
                d="M31.411 47.672l.062-1.953-.536-.452h-7.872l-.516.452.042 1.953-6.595-3.124 2.308 1.89 4.678 3.228H31l4.698-3.227 2.288-1.891-6.574 3.124z"
            ></path>
            <path
                fill="url(#paint17_linear_11808_11417)"
                d="M30.893 41.115l-.989-.699h-5.812l-.989.7-.556 4.604.515-.453h7.872l.536.453-.577-4.605z"
            ></path>
            <path
                fill="url(#paint18_linear_11808_11417)"
                d="M51.915 16.757l1.752-8.51L51.029.333 30.894 15.236l7.75 6.537 10.942 3.186 2.411-2.816-1.05-.76 1.668-1.522-1.277-.987 1.67-1.274-1.093-.843z"
            ></path>
            <path
                fill="url(#paint19_linear_11808_11417)"
                d="M.333 8.248l1.772 8.51-1.133.842 1.69 1.275-1.278.987 1.67 1.52-1.052.761 2.411 2.816 10.944-3.186 7.748-6.536L2.971.334.333 8.248z"
            ></path>
            <path
                fill="url(#paint20_radial_11808_11417)"
                d="M49.588 24.959l-10.943-3.186 3.297 4.974-4.925 9.6 6.512-.083h9.727L49.588 24.96z"
            ></path>
            <path
                fill="url(#paint21_linear_11808_11417)"
                d="M49.588 24.959l-10.943-3.186 3.297 4.974-4.925 9.6 6.512-.083h9.727L49.588 24.96z"
            ></path>
            <path
                fill="url(#paint22_radial_11808_11417)"
                d="M15.357 21.773L4.414 24.959.767 36.264h9.706l6.512.083-4.925-9.6 3.297-4.974z"
            ></path>
            <path
                fill="url(#paint23_linear_11808_11417)"
                d="M15.357 21.773L4.414 24.959.767 36.264h9.706l6.512.083-4.925-9.6 3.297-4.974z"
            ></path>
            <path
                fill="url(#paint24_linear_11808_11417)"
                d="M30.198 27.282l.7-12.046 3.174-8.571H19.935l3.173 8.571.7 12.046.269 3.782.02 9.353h5.812l.02-9.353.269-3.782z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_11808_11417"
                    x1="32.8"
                    x2="59.575"
                    y1="14.669"
                    y2="-12.527"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E17726"></stop>
                    <stop offset="1" stopColor="#E17726" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11808_11417"
                    x1="40.798"
                    x2="33.75"
                    y1="14.779"
                    y2="-7.92"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E27625"></stop>
                    <stop
                        offset="1"
                        stopColor="#FCA15C"
                        stopOpacity="0.83"
                    ></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_11808_11417"
                    x1="25.633"
                    x2="-0.72"
                    y1="15.934"
                    y2="-11.683"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E27625"></stop>
                    <stop offset="1" stopColor="#E27625" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_11808_11417"
                    x1="13.479"
                    x2="6.258"
                    y1="14.914"
                    y2="-7.922"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E27625"></stop>
                    <stop
                        offset="1"
                        stopColor="#FCA15C"
                        stopOpacity="0.83"
                    ></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear_11808_11417"
                    x1="35.331"
                    x2="50.304"
                    y1="36.173"
                    y2="57.52"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.256" stopColor="#E27625"></stop>
                    <stop offset="1" stopColor="#E27625" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear_11808_11417"
                    x1="45.757"
                    x2="40.221"
                    y1="47.076"
                    y2="29.914"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E27625"></stop>
                    <stop
                        offset="1"
                        stopColor="#FCA15C"
                        stopOpacity="0.83"
                    ></stop>
                </linearGradient>
                <linearGradient
                    id="paint6_linear_11808_11417"
                    x1="17.198"
                    x2="-2.197"
                    y1="36.173"
                    y2="53.883"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.247" stopColor="#E27625"></stop>
                    <stop offset="1" stopColor="#E27625" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint7_linear_11808_11417"
                    x1="8.517"
                    x2="2.969"
                    y1="47.076"
                    y2="29.923"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E27625"></stop>
                    <stop
                        offset="1"
                        stopColor="#FCA15C"
                        stopOpacity="0.83"
                    ></stop>
                </linearGradient>
                <linearGradient
                    id="paint8_linear_11808_11417"
                    x1="14.247"
                    x2="36.805"
                    y1="25.211"
                    y2="6.658"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E27625"></stop>
                    <stop offset="1" stopColor="#E27625" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint9_linear_11808_11417"
                    x1="18.038"
                    x2="10.382"
                    y1="26.485"
                    y2="9.535"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E27625"></stop>
                    <stop
                        offset="1"
                        stopColor="#FCA15C"
                        stopOpacity="0.83"
                    ></stop>
                </linearGradient>
                <linearGradient
                    id="paint10_linear_11808_11417"
                    x1="36.173"
                    x2="28.373"
                    y1="26.476"
                    y2="9.399"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E27625"></stop>
                    <stop
                        offset="1"
                        stopColor="#FCA15C"
                        stopOpacity="0.83"
                    ></stop>
                </linearGradient>
                <linearGradient
                    id="paint11_linear_11808_11417"
                    x1="34.439"
                    x2="42.072"
                    y1="41.115"
                    y2="58.191"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D5BFB2"></stop>
                    <stop offset="1" stopColor="#D5BFB2" stopOpacity="0"></stop>
                </linearGradient>
                <radialGradient
                    id="paint12_radial_11808_11417"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-15.23496 29.92612 -32.44847 -16.51906 23.03 39.69)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D5BFB2"></stop>
                    <stop offset="1" stopColor="#D5BFB2" stopOpacity="0"></stop>
                </radialGradient>
                <linearGradient
                    id="paint13_linear_11808_11417"
                    x1="19.767"
                    x2="19.767"
                    y1="29.665"
                    y2="33.303"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#041836"></stop>
                    <stop offset="1" stopColor="#17396B"></stop>
                </linearGradient>
                <linearGradient
                    id="paint14_linear_11808_11417"
                    x1="34.246"
                    x2="34.246"
                    y1="29.665"
                    y2="33.303"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#041836"></stop>
                    <stop offset="1" stopColor="#17396B"></stop>
                </linearGradient>
                <linearGradient
                    id="paint15_linear_11808_11417"
                    x1="36.381"
                    x2="17.618"
                    y1="31.536"
                    y2="53.04"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.2" stopColor="#F5841F"></stop>
                    <stop offset="1" stopColor="#F5841F" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint16_linear_11808_11417"
                    x1="16.777"
                    x2="31.746"
                    y1="31.536"
                    y2="51.142"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.328" stopColor="#F5841F"></stop>
                    <stop offset="1" stopColor="#F5841F" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint17_linear_11808_11417"
                    x1="27.009"
                    x2="27.009"
                    y1="40.416"
                    y2="45.72"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#041836"></stop>
                    <stop offset="1" stopColor="#17396B"></stop>
                </linearGradient>
                <linearGradient
                    id="paint18_linear_11808_11417"
                    x1="37.464"
                    x2="53.59"
                    y1="19.699"
                    y2="8.548"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#592B0D"></stop>
                    <stop offset="1" stopColor="#763E1A"></stop>
                </linearGradient>
                <linearGradient
                    id="paint19_linear_11808_11417"
                    x1="21.511"
                    x2="1.439"
                    y1="16.268"
                    y2="5.46"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#592B0D"></stop>
                    <stop offset="1" stopColor="#763E1A"></stop>
                </linearGradient>
                <radialGradient
                    id="paint20_radial_11808_11417"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(11.689 -117.22 213.297) scale(23.489 26.1736)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.264" stopColor="#F5841F"></stop>
                    <stop offset="1" stopColor="#F5841F" stopOpacity="0"></stop>
                </radialGradient>
                <linearGradient
                    id="paint21_linear_11808_11417"
                    x1="45.281"
                    x2="37.521"
                    y1="35.428"
                    y2="14.824"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E27625"></stop>
                    <stop
                        offset="1"
                        stopColor="#FCA15C"
                        stopOpacity="0.83"
                    ></stop>
                </linearGradient>
                <radialGradient
                    id="paint22_radial_11808_11417"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(-159.818 12.556 16.425) scale(41.5536 46.244)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.3" stopColor="#F5841F"></stop>
                    <stop
                        offset="0.567"
                        stopColor="#F5841F"
                        stopOpacity="0"
                    ></stop>
                </radialGradient>
                <linearGradient
                    id="paint23_linear_11808_11417"
                    x1="9.02"
                    x2="1.253"
                    y1="35.428"
                    y2="14.831"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E27625"></stop>
                    <stop
                        offset="1"
                        stopColor="#FCA15C"
                        stopOpacity="0.83"
                    ></stop>
                </linearGradient>
                <linearGradient
                    id="paint24_linear_11808_11417"
                    x1="26.898"
                    x2="26.898"
                    y1="6.658"
                    y2="74.544"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.268" stopColor="#F5841F"></stop>
                    <stop
                        offset="1"
                        stopColor="#FFC796"
                        stopOpacity="0.89"
                    ></stop>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default Metamask;
