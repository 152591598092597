/* eslint-disable linebreak-style */
import React from 'react';

const dappsIcon = (fill: string, size: number, style?: React.CSSProperties) => (
    <svg
        aria-hidden="true"
        data-testid="test-icon"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_359_27721)" style={style}>
            <path
                d="M5.62538 12.5953C5.54545 12.6079 5.46415 12.6079 5.38422 12.5953L2.55464 11.5978C2.45223 11.5662 2.35688 11.5137 2.27416 11.4432C2.19144 11.3728 2.12301 11.2859 2.07286 11.1875C2.02272 11.0892 1.99187 10.9814 1.98212 10.8705C1.97237 10.7596 1.9839 10.6478 2.01605 10.5416L2.93245 7.59947C2.96553 7.49487 3.01806 7.3981 3.08702 7.31468C3.15599 7.23126 3.24004 7.16283 3.33438 7.1133C3.52367 7.01307 3.74329 6.99499 3.94532 7.06301L6.76686 8.01858C6.86783 8.05191 6.96148 8.10572 7.04244 8.17691C7.12341 8.2481 7.19008 8.33527 7.23863 8.43342C7.28719 8.53156 7.31666 8.63874 7.32537 8.7488C7.33408 8.85886 7.32184 8.96962 7.28937 9.07474L6.39708 12.0169C6.32589 12.2255 6.18255 12.3989 5.99516 12.5031C5.88074 12.5642 5.75394 12.5958 5.62538 12.5953ZM3.8167 10.265L5.11091 10.7009L5.53696 9.33459L4.24274 8.89871L3.8167 10.265Z"
                fill={fill || '#2E7DAF'}
            />
            <path
                d="M10.6093 6.81986C10.3961 6.81986 10.1916 6.73155 10.0409 6.57435C9.89011 6.41716 9.80542 6.20395 9.80542 5.98164V2.88022C9.80844 2.65912 9.89512 2.44822 10.0466 2.29346C10.195 2.13553 10.3972 2.04514 10.6093 2.04199H13.5755C13.7887 2.04199 13.9932 2.1303 14.1439 2.2875C14.2947 2.4447 14.3794 2.6579 14.3794 2.88022V5.98164C14.3794 6.20395 14.2947 6.41716 14.1439 6.57435C13.9932 6.73155 13.7887 6.81986 13.5755 6.81986H10.6093ZM11.4131 3.71844V5.14342H12.7717V3.71844H11.4131Z"
                fill={fill || '#2E7DAF'}
            />
            <path
                d="M18.3746 12.8638C18.1983 12.8728 18.024 12.8211 17.8788 12.7165C17.7335 12.612 17.6252 12.4605 17.5707 12.2855L16.6945 9.33491C16.6286 9.12371 16.6457 8.89386 16.7422 8.69584C16.8386 8.49781 17.0065 8.3478 17.209 8.27875L20.0305 7.31479C20.1313 7.28093 20.2376 7.26817 20.3431 7.27725C20.4487 7.28633 20.5514 7.31707 20.6456 7.3677C20.7397 7.41833 20.8233 7.48785 20.8916 7.57228C20.9598 7.6567 21.0114 7.75436 21.0434 7.85964L21.9598 10.8018C21.9959 10.9054 22.0103 11.0159 22.0019 11.1258C21.9936 11.2357 21.9628 11.3424 21.9116 11.4389C21.8641 11.5372 21.7984 11.6249 21.7184 11.6968C21.6384 11.7687 21.5456 11.8235 21.4453 11.858L18.6238 12.8135C18.5434 12.8425 18.4595 12.8594 18.3746 12.8638ZM18.471 9.61991L18.8971 11.0114L20.1913 10.5671L19.7733 9.22594L18.471 9.61991Z"
                fill={fill || '#2E7DAF'}
            />
            <path
                d="M16.0514 21.9996C15.9246 22 15.7995 21.969 15.6863 21.9093C15.5731 21.8496 15.4751 21.7627 15.4003 21.656L13.6559 19.1413C13.5392 18.9713 13.4876 18.7621 13.5112 18.5545C13.5451 18.3357 13.6607 18.1398 13.8328 18.0097L16.2443 16.1907C16.3279 16.1237 16.424 16.0757 16.5264 16.0497C16.6289 16.0237 16.7354 16.0203 16.8392 16.0399C17.049 16.0752 17.2369 16.1957 17.3617 16.3751L19.1061 18.8898C19.2166 19.0705 19.2565 19.2885 19.2176 19.499C19.1788 19.7095 19.064 19.8964 18.8971 20.0214L16.4855 21.8404C16.3588 21.9364 16.2079 21.9918 16.0514 21.9996ZM15.4324 18.8479L16.2363 19.9963L17.3376 19.158L16.5337 18.0097L15.4324 18.8479Z"
                fill={fill || '#2E7DAF'}
            />
            <path
                d="M7.64306 21.8405C7.47188 21.8387 7.30572 21.7799 7.16878 21.6728L4.75721 19.8539C4.58645 19.7231 4.47208 19.5274 4.43896 19.3092C4.40585 19.0911 4.45666 18.8681 4.58036 18.6887L6.32473 16.1741C6.39056 16.0861 6.47255 16.0128 6.56591 15.9583C6.65927 15.9038 6.76213 15.8693 6.86844 15.8568C6.97475 15.8443 7.08238 15.8541 7.18503 15.8855C7.28768 15.917 7.38327 15.9695 7.46621 16.04L9.87779 17.8589C10.0498 17.989 10.1654 18.1849 10.1993 18.4037C10.2163 18.5133 10.2123 18.6253 10.1874 18.7332C10.1626 18.8411 10.1175 18.9428 10.0546 19.0324L8.31026 21.5471C8.22881 21.647 8.12602 21.7256 8.01014 21.7765C7.89426 21.8275 7.76853 21.8494 7.64306 21.8405ZM6.36493 18.9905L7.46621 19.8287L8.27007 18.6804L7.16878 17.8421L6.36493 18.9905Z"
                fill={fill || '#2E7DAF'}
            />
            <path
                d="M5.62538 12.5953C5.54545 12.6079 5.46415 12.6079 5.38422 12.5953L2.55464 11.5978C2.45223 11.5662 2.35688 11.5137 2.27416 11.4432C2.19144 11.3728 2.12301 11.2859 2.07286 11.1875C2.02272 11.0892 1.99187 10.9814 1.98212 10.8705C1.97237 10.7596 1.9839 10.6478 2.01605 10.5416L2.93245 7.59947C2.96553 7.49487 3.01806 7.3981 3.08702 7.31468C3.15599 7.23126 3.24004 7.16283 3.33438 7.1133C3.52367 7.01307 3.74329 6.99499 3.94532 7.06301L6.76686 8.01858C6.86783 8.05191 6.96148 8.10572 7.04244 8.17691C7.12341 8.2481 7.19008 8.33527 7.23863 8.43342C7.28719 8.53156 7.31666 8.63874 7.32537 8.7488C7.33408 8.85886 7.32184 8.96962 7.28937 9.07474L6.39708 12.0169C6.32589 12.2255 6.18255 12.3989 5.99516 12.5031C5.88074 12.5642 5.75394 12.5958 5.62538 12.5953ZM3.8167 10.265L5.11091 10.7009L5.53696 9.33459L4.24274 8.89871L3.8167 10.265Z"
                fill={fill || '#2E7DAF'}
            />
            <path
                d="M8.22992 8.11039C8.10309 8.11073 7.97797 8.07977 7.8648 8.02004C7.75164 7.96031 7.65363 7.8735 7.5788 7.76671C7.5163 7.67764 7.47129 7.57656 7.44634 7.4693C7.4214 7.36203 7.41702 7.25069 7.43345 7.14165C7.44988 7.03262 7.4868 6.92804 7.54209 6.83392C7.59738 6.7398 7.66996 6.658 7.75565 6.5932L8.13346 6.3082C8.21889 6.24303 8.31582 6.1961 8.41868 6.17009C8.52155 6.14408 8.62833 6.13951 8.7329 6.15665C8.83746 6.17378 8.93775 6.21228 9.02801 6.26993C9.11827 6.32759 9.19672 6.40326 9.25886 6.49261C9.32136 6.58169 9.36638 6.68276 9.39132 6.79003C9.41626 6.89729 9.42064 7.00864 9.40421 7.11768C9.38778 7.22671 9.35086 7.33129 9.29557 7.42541C9.24028 7.51952 9.1677 7.60133 9.08201 7.66612L8.7042 7.95112C8.56627 8.05525 8.4002 8.11102 8.22992 8.11039Z"
                fill={fill || '#2E7DAF'}
            />
            <path
                d="M15.9148 8.21935C15.7473 8.21822 15.5843 8.16254 15.4486 8.06009L15.0547 7.76671C14.969 7.70192 14.8965 7.62011 14.8412 7.52599C14.7859 7.43188 14.749 7.3273 14.7325 7.21826C14.7161 7.10923 14.7205 6.99788 14.7454 6.89062C14.7704 6.78335 14.8154 6.68228 14.8779 6.5932C14.94 6.50385 15.0185 6.42817 15.1087 6.37052C15.199 6.31286 15.2993 6.27437 15.4038 6.25723C15.5084 6.2401 15.6152 6.24467 15.718 6.27067C15.8209 6.29668 15.9178 6.34362 16.0033 6.40879L16.3891 6.70217C16.5269 6.8062 16.6295 6.95313 16.6821 7.12182C16.7348 7.29051 16.7347 7.47226 16.682 7.64092C16.6293 7.80957 16.5266 7.95644 16.3887 8.06039C16.2509 8.16433 16.085 8.21999 15.9148 8.21935Z"
                fill={fill || '#2E7DAF'}
            />
            <path
                d="M5.76208 15.5713C5.59256 15.5711 5.42742 15.5151 5.29027 15.4112C5.15311 15.3074 5.05096 15.1609 4.99841 14.9929L4.79745 14.3642C4.76009 14.2584 4.74358 14.1459 4.7489 14.0333C4.75422 13.9207 4.78125 13.8104 4.82839 13.709C4.87553 13.6076 4.94181 13.5171 5.02326 13.443C5.1047 13.3689 5.19965 13.3126 5.30241 13.2777C5.40518 13.2427 5.51364 13.2297 5.62134 13.2395C5.72903 13.2492 5.83373 13.2815 5.92917 13.3344C6.02461 13.3874 6.10884 13.4598 6.17682 13.5474C6.24479 13.6351 6.29511 13.7361 6.32478 13.8445L6.52574 14.4816C6.59039 14.6884 6.57538 14.9134 6.48387 15.1089C6.39237 15.3044 6.23155 15.4552 6.03539 15.5293C5.94786 15.5621 5.85495 15.5764 5.76208 15.5713Z"
                fill={fill || '#2E7DAF'}
            />
            <path
                d="M12.2411 20.4323H11.373C11.1598 20.4323 10.9553 20.344 10.8045 20.1868C10.6538 20.0296 10.5691 19.8164 10.5691 19.5941C10.5691 19.3718 10.6538 19.1586 10.8045 19.0014C10.9553 18.8442 11.1598 18.7559 11.373 18.7559H12.2411C12.4543 18.7559 12.6588 18.8442 12.8095 19.0014C12.9603 19.1586 13.045 19.3718 13.045 19.5941C13.045 19.8164 12.9603 20.0296 12.8095 20.1868C12.6588 20.344 12.4543 20.4323 12.2411 20.4323Z"
                fill={fill || '#2E7DAF'}
            />
            <path
                d="M18.1495 15.8808C18.0668 15.8928 17.9829 15.8928 17.9003 15.8808C17.6978 15.8117 17.5299 15.6617 17.4335 15.4637C17.337 15.2657 17.3199 15.0358 17.3858 14.8246L17.5948 14.154C17.6667 13.9505 17.8114 13.784 17.9986 13.6894C18.1857 13.5948 18.4008 13.5795 18.5985 13.6468C18.7962 13.7141 18.9613 13.8587 19.0589 14.0502C19.1565 14.2417 19.1792 14.4652 19.1221 14.6737L18.9131 15.3443C18.8539 15.5045 18.7488 15.6419 18.6123 15.7378C18.4757 15.8338 18.3141 15.8837 18.1495 15.8808Z"
                fill={fill || '#2E7DAF'}
            />
            <path
                d="M12.0965 15.7136C11.4698 15.7152 10.8566 15.523 10.3347 15.1611C9.81282 14.7992 9.40562 14.284 9.16467 13.6807C8.92372 13.0773 8.85985 12.413 8.98115 11.7718C9.10244 11.1306 9.40346 10.5414 9.84608 10.0787C10.2887 9.61596 10.853 9.30059 11.4676 9.17247C12.0822 9.04436 12.7194 9.10927 13.2986 9.35899C13.8778 9.60871 14.373 10.032 14.7213 10.5753C15.0697 11.1186 15.2557 11.7574 15.2557 12.411C15.2557 13.2854 14.9231 14.1242 14.3309 14.7433C13.7386 15.3624 12.9351 15.7114 12.0965 15.7136ZM12.0965 10.7932C11.7874 10.7916 11.4848 10.8857 11.2271 11.0638C10.9694 11.2418 10.7683 11.4957 10.6493 11.7932C10.5302 12.0907 10.4987 12.4183 10.5586 12.7345C10.6185 13.0508 10.7672 13.3413 10.9858 13.5692C11.2043 13.7971 11.483 13.9522 11.7862 14.0147C12.0895 14.0771 12.4037 14.0442 12.689 13.9201C12.9743 13.796 13.2177 13.5863 13.3885 13.3176C13.5592 13.0489 13.6495 12.7333 13.648 12.411C13.648 11.9819 13.4845 11.5704 13.1935 11.267C12.9026 10.9637 12.508 10.7932 12.0965 10.7932Z"
                fill={fill || '#2E7DAF'}
            />
        </g>
        <defs>
            <clipPath id="clip0_359_27721">
                <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(2 2)"
                />
            </clipPath>
        </defs>
    </svg>
);
export default dappsIcon;
