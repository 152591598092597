/* eslint-disable linebreak-style */
import React from 'react';

const uniIcon = (fill: string, size: number, style?: React.CSSProperties) => (
    <svg
        aria-hidden="true"
        data-testid="test-icon"
        id="uniswap-uni-logo"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size * 1.1517916}
        viewBox="0 0 24 27.643"
        style={style}
    >
        <title>uni icon</title>
        <path
            id="Path_1728"
            data-name="Path 1728"
            d="M64.444,43.585c-.3-.043-.314-.057-.171-.071a4.7,4.7,0,0,1,1.34.114,5.762,5.762,0,0,1,2.937,1.967l.257.3.371-.057a7.949,7.949,0,0,1,4.562.57,5.909,5.909,0,0,1,1.026.6,1.378,1.378,0,0,1,.1.4,2.417,2.417,0,0,1-.157,1.554.417.417,0,0,0-.043.456.429.429,0,0,0,.385.242c.342,0,.7-.542.87-1.3l.071-.3.128.143A5.614,5.614,0,0,1,77.5,50.941l.029.214L77.4,50.97a2.407,2.407,0,0,0-.684-.727,4.282,4.282,0,0,0-2.352-.5,9.638,9.638,0,0,1-2.609-.4c-1.169-.385-1.768-.884-3.151-2.723A13.1,13.1,0,0,0,67.224,45,4.836,4.836,0,0,0,64.444,43.585Z"
            transform="translate(-55.034 -37.298)"
            fill={fill || '#FF007A'}
        />
        <path
            id="Path_1729"
            data-name="Path 1729"
            d="M128.92,47.968a3.083,3.083,0,0,1,.257-1.226.982.982,0,0,1,.128-.242c.014,0-.014.1-.057.214a2.723,2.723,0,0,0-.057,1.255c.1.642.143.727.827,1.426.314.328.684.741.827.912l.242.314-.242-.228a8.677,8.677,0,0,0-1.14-.9c-.1-.057-.114-.057-.185.014s-.071.143-.071.556a3.054,3.054,0,0,1-.314,1.454c-.114.214-.128.171-.029-.071a2.077,2.077,0,0,0,.086-.884c0-1.24-.143-1.54-1.012-2.039-.214-.128-.585-.314-.8-.413a3.158,3.158,0,0,1-.385-.185,6.37,6.37,0,0,1,1.2.356c.5.2.585.214.642.2C128.877,48.439,128.905,48.325,128.92,47.968Z"
            transform="translate(-108.889 -39.871)"
            fill={fill || '#FF007A'}
        />
        <path
            id="Path_1730"
            data-name="Path 1730"
            d="M64.627,51.464a5.037,5.037,0,0,1-.9-3.065l.029-.3.143.029a3.345,3.345,0,0,1,.912.342c.57.342.827.813,1.069,1.982.071.342.171.741.214.87a5.146,5.146,0,0,0,.57,1.026c.157.228.057.342-.3.314A2.921,2.921,0,0,1,64.627,51.464Z"
            transform="translate(-54.633 -41.243)"
            fill={fill || '#FF007A'}
        />
        <path
            id="Path_1731"
            data-name="Path 1731"
            d="M112.421,89.734c-2.823-1.141-3.821-2.124-3.821-3.792,0-.242.014-.442.014-.442a2.676,2.676,0,0,1,.242.185c.57.456,1.212.656,2.994.912a12.42,12.42,0,0,1,2.181.456,4.165,4.165,0,0,1,3.051,3.322A5.766,5.766,0,0,1,117,92.158a3.574,3.574,0,0,1-.456,1.026c-.014,0-.043-.071-.043-.185a2.32,2.32,0,0,0-.827-1.611A11.392,11.392,0,0,0,112.421,89.734Z"
            transform="translate(-93.117 -73.311)"
            fill={fill || '#FF007A'}
        />
        <path
            id="Path_1732"
            data-name="Path 1732"
            d="M84.951,113.613a3.377,3.377,0,0,0-.143-.6l-.071-.214.128.157a2.51,2.51,0,0,1,.456.827,1.78,1.78,0,0,1,.1.8,1.819,1.819,0,0,1-.1.77,2.58,2.58,0,0,1-.6.955,3.431,3.431,0,0,1-2.138.941c-.171.014-.656.057-1.083.086a8.362,8.362,0,0,0-2.424.4c-.086.029-.171.057-.185.043a3.229,3.229,0,0,1,.77-.456,7.759,7.759,0,0,1,2.138-.57,9.766,9.766,0,0,0,1.269-.257A2.465,2.465,0,0,0,84.951,113.613Z"
            transform="translate(-67.643 -96.719)"
            fill={fill || '#FF007A'}
        />
        <path
            id="Path_1733"
            data-name="Path 1733"
            d="M128.326,119.852a3.327,3.327,0,0,1-.257-2.309c.029-.071.057-.143.086-.143a.613.613,0,0,1,.2.1c.171.114.527.314,1.44.813a7.281,7.281,0,0,1,2.267,1.668,3.526,3.526,0,0,1,.756,1.725,5.655,5.655,0,0,1-.071,1.7,4.2,4.2,0,0,1-2.067,2.737,1.981,1.981,0,0,1-.3.143,1.134,1.134,0,0,1,.128-.314,2.83,2.83,0,0,0,.128-2.21,9.077,9.077,0,0,0-1.141-2.053A13.544,13.544,0,0,1,128.326,119.852Z"
            transform="translate(-109.722 -100.663)"
            fill={fill || '#FF007A'}
        />
        <path
            id="Path_1734"
            data-name="Path 1734"
            d="M54.2,154.617a7.75,7.75,0,0,1,3.55-1.711,5.282,5.282,0,0,1,1.839.071,3.175,3.175,0,0,1,1.8,1.155,4.257,4.257,0,0,1,.656,1.939c.057.385.128.784.143.87a1.913,1.913,0,0,0,.627,1.126,2.169,2.169,0,0,0,1.967.057.956.956,0,0,1,.242-.071,2.185,2.185,0,0,1-.613.413,2.018,2.018,0,0,1-1.026.242,2.058,2.058,0,0,1-1.739-1.069,8.012,8.012,0,0,1-.47-.941,3.428,3.428,0,0,0-1.34-1.882,1.757,1.757,0,0,0-1.668-.157,1.074,1.074,0,0,0-.371,1.668,1.458,1.458,0,0,0,.813.428.87.87,0,0,0,.984-.87.683.683,0,0,0-.47-.7c-.456-.2-.955.029-.941.47a.392.392,0,0,0,.271.385c.114.057.114.057.029.043a.529.529,0,0,1-.185-.927,1.026,1.026,0,0,1,1.525.328,1.494,1.494,0,0,1,.029.969,1.333,1.333,0,0,1-1.625.77,2.325,2.325,0,0,1-1.24-.841,3.788,3.788,0,0,0-2.808-1.44L53.9,154.9Z"
            transform="translate(-46.215 -131.066)"
            fill={fill || '#FF007A'}
        />
        <path
            id="Path_1735"
            data-name="Path 1735"
            d="M.481.613C3.8,4.647,8.921,10.92,9.177,11.262c.214.285.128.556-.228.756a2.181,2.181,0,0,1-.813.228,1.029,1.029,0,0,1-.684-.3c-.128-.128-.684-.941-1.939-2.894C4.558,7.556,3.746,6.315,3.731,6.3c-.057-.029-.057-.029,1.682,3.079a24.954,24.954,0,0,1,1.454,2.737c0,.185-.057.285-.285.542A3.172,3.172,0,0,0,5.9,14.584a5.965,5.965,0,0,1-1.625,3.279,5.867,5.867,0,0,0-.9,1.269,2.411,2.411,0,0,0-.285,1.14,3.567,3.567,0,0,0,.185,1.4,4.762,4.762,0,0,0,.684,1.34,3.317,3.317,0,0,1,.527,1.041c0,.114.029.114.542,0a5.639,5.639,0,0,0,2.794-1.369A1.29,1.29,0,0,0,8.25,21.6a1.163,1.163,0,0,0-.1-.6,2.559,2.559,0,0,0-1-.969A2.253,2.253,0,0,1,5.969,18.5a2.683,2.683,0,0,1,.442-1.711,5.113,5.113,0,0,0,.627-2.252c.043-.6.114-.841.285-1.026a1.063,1.063,0,0,1,.784-.328,2.613,2.613,0,0,0,1.568-.642,1.34,1.34,0,0,0,.485-1.041l.014-.328-.185-.2C9.32,10.207.039,0,0,0-.018,0,.21.271.481.613ZM4.858,20.885a.589.589,0,0,0-.185-.784c-.242-.157-.613-.086-.613.128a.146.146,0,0,0,.114.143c.128.071.143.143.043.3s-.1.3.029.4A.408.408,0,0,0,4.858,20.885Z"
            transform="translate(0.004)"
            fill={fill || '#FF007A'}
            fillRule="evenodd"
        />
        <path
            id="Path_1736"
            data-name="Path 1736"
            d="M69.8,93.69a1.266,1.266,0,0,0-.77.841,1.228,1.228,0,0,0,.071.77c.157.2.3.257.7.257.784,0,1.454-.342,1.525-.756a1.1,1.1,0,0,0-.642-1.026A1.449,1.449,0,0,0,69.8,93.69Zm.912.713a.321.321,0,0,0-.143-.485c-.385-.242-.969-.043-.969.328,0,.185.3.385.585.385A.787.787,0,0,0,70.707,94.4Z"
            transform="translate(-59.159 -80.304)"
            fill={fill || '#FF007A'}
            fillRule="evenodd"
        />
    </svg>
);
export default uniIcon;
