import React from 'react';

const lockResetIcon = (
    fill: string,
    size: number,
    style?: React.CSSProperties,
) => (
    <svg
        fill={fill}
        height={size}
        style={style}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        data-testid="test-icon"
    >
        <title>Lock Reset Icon</title>
        <g clipPath="url(#clip0_16372_180596)">
            <path d="M17.9601 22H4.51006C3.96006 22 3.51006 21.55 3.51006 21C3.51006 20.45 3.96006 20 4.51006 20H17.9501C18.3701 20 18.7301 19.54 18.7301 19V14.05C18.7301 13.5 19.1801 13.05 19.7301 13.05C20.2801 13.05 20.7301 13.5 20.7301 14.05V19C20.7301 20.65 19.4801 22 17.9501 22H17.9601Z" />
            <path d="M3.74006 17.55C3.19006 17.55 2.74006 17.1 2.74006 16.55V12C2.74006 10.35 3.99006 9 5.52006 9H18.9601C19.5101 9 19.9601 9.45 19.9601 10C19.9601 10.55 19.5101 11 18.9601 11H5.51006C5.09006 11 4.73006 11.46 4.73006 12V16.55C4.73006 17.1 4.28006 17.55 3.73006 17.55H3.74006Z" />
            <path d="M16.7401 11C16.1901 11 15.7401 10.55 15.7401 10V8C15.7401 5.79 13.9501 4 11.7401 4C9.53006 4 7.74006 5.79 7.74006 8V10C7.74006 10.55 7.29006 11 6.74006 11C6.19006 11 5.74006 10.55 5.74006 10V8C5.74006 4.69 8.43006 2 11.7401 2C15.0501 2 17.7401 4.69 17.7401 8V10C17.7401 10.55 17.2901 11 16.7401 11Z" />
            <path d="M22.2601 17.26C22.0001 17.26 21.7501 17.16 21.5501 16.97L19.6301 15.05L17.7101 16.97C17.3201 17.36 16.6901 17.36 16.3001 16.97C15.9101 16.58 15.9101 15.95 16.3001 15.56L18.9301 12.93C19.3201 12.54 19.9501 12.54 20.3401 12.93L22.9701 15.56C23.3601 15.95 23.3601 16.58 22.9701 16.97C22.7701 17.17 22.5201 17.26 22.2601 17.26Z" />
            <path d="M3.63006 17.89C3.37006 17.89 3.12006 17.79 2.92006 17.6L0.290059 14.97C-0.0999414 14.58 -0.0999414 13.95 0.290059 13.56C0.680059 13.17 1.31006 13.17 1.70006 13.56L3.62006 15.48L5.54006 13.56C5.93006 13.17 6.56006 13.17 6.95006 13.56C7.34006 13.95 7.34006 14.58 6.95006 14.97L4.32006 17.6C4.12006 17.8 3.87006 17.89 3.61006 17.89H3.63006Z" />
            <path d="M11.7401 17.69C11.1901 17.69 10.7401 17.24 10.7401 16.69V14.69C10.7401 14.14 11.1901 13.69 11.7401 13.69C12.2901 13.69 12.7401 14.14 12.7401 14.69V16.69C12.7401 17.24 12.2901 17.69 11.7401 17.69Z" />
        </g>
        <defs>
            <clipPath id="clip0_16372_180596">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default lockResetIcon;
