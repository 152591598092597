/* eslint-disable linebreak-style */
import React from 'react';

const solanaIcon = (
    fill: string,
    size: number,
    style?: React.CSSProperties,
) => (
    <svg
        aria-hidden="true"
        data-testid="test-icon"
        fill={fill}
        height={size}
        style={style}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M5.24936 15.9558C5.37005 15.8352 5.53598 15.7648 5.71198 15.7648H21.6721C21.9638 15.7648 22.1096 16.1166 21.9034 16.3227L18.7506 19.4737C18.63 19.5943 18.464 19.6647 18.288 19.6647H2.32786C2.03621 19.6647 1.89039 19.3129 2.09655 19.1068L5.24936 15.9558Z" />
        <path d="M5.24936 4.19097C5.37507 4.07036 5.54101 4 5.71198 4H21.6721C21.9638 4 22.1096 4.35179 21.9034 4.55784L18.7506 7.70888C18.63 7.82949 18.464 7.89985 18.288 7.89985H2.32786C2.03621 7.89985 1.89039 7.54806 2.09655 7.34201L5.24936 4.19097Z" />
        <path d="M18.7506 10.1861C18.63 10.0686 18.464 10 18.288 10H2.32786C2.03621 10 1.89039 10.3428 2.09655 10.5436L5.24936 13.6139C5.37005 13.7314 5.53598 13.8 5.71198 13.8H21.6721C21.9638 13.8 22.1096 13.4572 21.9034 13.2564L18.7506 10.1861Z" />
    </svg>
);
export default solanaIcon;
