import React from 'react';

const keyIcon = (fill: string, size: number, style?: React.CSSProperties) => (
    <svg
        fill={fill}
        height={size}
        style={style}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        data-testid="test-icon"
    >
        <title>Key Icon</title>
        <path
            d="M15.13 7.91457L18.2908 4.78712M20.0969 3L18.2908 4.78712L20.0969 3ZM11.4184 11.5871C11.8847 12.0423 12.2554 12.5843 12.5091 13.1819C12.7628 13.7795 12.8945 14.4208 12.8967 15.069C12.8989 15.7171 12.7715 16.3593 12.5219 16.9585C12.2722 17.5578 11.9052 18.1022 11.442 18.5606C10.9788 19.0189 10.4285 19.382 9.82292 19.629C9.2173 19.8761 8.56828 20.0021 7.91322 20C7.25816 19.9978 6.61 19.8674 6.00607 19.6164C5.40214 19.3653 4.85436 18.9986 4.39427 18.5372C3.48951 17.6103 2.98887 16.3689 3.00019 15.0803C3.0115 13.7917 3.53387 12.5591 4.45477 11.6479C5.37568 10.7367 6.62144 10.2198 7.92375 10.2086C9.22606 10.1974 10.4807 10.6928 11.4175 11.588L11.4184 11.5871ZM11.4184 11.5871L15.13 7.91457L11.4184 11.5871ZM15.13 7.91457L17.8392 10.5952L21 7.46779L18.2908 4.78712L15.13 7.91457Z"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.75"
            stroke="black"
        />
    </svg>
);
export default keyIcon;
