import React from 'react';

const cartBagIcon = (
    fill: string,
    size: number,
    style?: React.CSSProperties,
) => (
    <svg
        fill={fill}
        height={size}
        style={style}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        data-testid="test-icon"
    >
        <title>Cart Bag Icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.064 2.55358C6.20545 2.36262 6.42903 2.25 6.66667 2.25H17.3333C17.571 2.25 17.7946 2.36262 17.936 2.55358L20.6027 6.15358C20.6984 6.28275 20.75 6.43925 20.75 6.6V19.2C20.75 19.8733 20.4859 20.5209 20.0129 20.9998C19.5396 21.479 18.8957 21.75 18.2222 21.75H5.77778C5.10434 21.75 4.4604 21.479 3.98709 20.9998C3.51408 20.5209 3.25 19.8733 3.25 19.2V6.6C3.25 6.43925 3.30165 6.28275 3.39733 6.15358L6.064 2.55358ZM7.04446 3.75L5.48891 5.85H18.5111L16.9555 3.75H7.04446ZM19.25 7.35H4.75V19.2C4.75 19.4815 4.86052 19.7495 5.05431 19.9458C5.2478 20.1417 5.50822 20.25 5.77778 20.25H18.2222C18.4918 20.25 18.7522 20.1417 18.9457 19.9458C19.1395 19.7495 19.25 19.4815 19.25 19.2V7.35ZM8.44444 9.45C8.85866 9.45 9.19444 9.78579 9.19444 10.2C9.19444 10.9589 9.49226 11.6848 10.0195 12.2186C10.5463 12.752 11.2589 13.05 12 13.05C12.7411 13.05 13.4537 12.752 13.9805 12.2186C14.5077 11.6848 14.8056 10.9589 14.8056 10.2C14.8056 9.78579 15.1413 9.45 15.5556 9.45C15.9698 9.45 16.3056 9.78579 16.3056 10.2C16.3056 11.3507 15.8542 12.4561 15.0478 13.2726C14.2411 14.0894 13.1449 14.55 12 14.55C10.8551 14.55 9.75894 14.0894 8.95223 13.2726C8.14583 12.4561 7.69444 11.3507 7.69444 10.2C7.69444 9.78579 8.03023 9.45 8.44444 9.45Z"
        />
    </svg>
);
export default cartBagIcon;
