import React from 'react';

const SafePal = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="40"
            height="40"
            version="1.1"
            viewBox="0 0 256 256"
        >
            <defs>
                <linearGradient id="linearGradient1452">
                    <stop offset="0" stopColor="#4a69fe" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#4d2bd9" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient
                    id="linearGradient1454"
                    x1="37.515"
                    x2="222.687"
                    y1="43.175"
                    y2="212.735"
                    gradientTransform="translate(-1.028 -1.028)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient1452"
                ></linearGradient>
            </defs>
            <circle
                cx="128"
                cy="128"
                r="128"
                style={{ fillRule: 'evenodd', strokeWidth: '9.005' }}
                fill="url(#linearGradient1454)"
                fillOpacity="1"
                opacity="0.98"
            ></circle>
            <path
                fill="#fff"
                stroke="none"
                d="M205.421 142.02v12.504c0 34.738-51.032 54.731-66.675 60.034l-5.689 1.942v-21.61l2.78-1.037c24.337-9.047 48.892-24.717 48.892-39.33V142.02zM128.133 39.5l20.855 6.351v22.05l-20.855-6.374-1.364.418v49.609h22.22v20.44h-22.22v84.203l-5.794-2.131c-2.69-.988-7.022-2.67-12.383-5.072l-2.518-1.135V46.223zm-28.605 8.8v21.948l-28.257 8.664v32.642h28.257v94.538l-6.258-3.179c-19.476-9.9-42.691-26.595-42.691-49.905V142.02H71.27v10.988c0 4.824 2.541 9.891 7.562 15.11v-36.124H50.58V63.223zm55.742-.523l50.151 15.33v68.887h-29.445v29.828s-6.583 8.524-20.688 15.662l-.014-101.545zm20.706 28.367v35.41h8.753V78.813z"
                style={{ fillRule: 'evenodd', strokeWidth: '4.99763' }}
            ></path>
        </svg>
    );
};

export default SafePal;
