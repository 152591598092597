import React from 'react';

const TokenPocket = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 1024 1024"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g mask="url(#mask0_408_225)">
                <path d="M1041.52 0H-27V1024H1041.52V0Z" fill="#2980FE" />
                <g clipPath="url(#clip0_408_225)">
                    <path
                        d="M406.796 438.643H406.927C406.796 437.857 406.796 436.94 406.796 436.154V438.643Z"
                        fill="#29AEFF"
                    />
                    <path
                        d="M667.602 463.533H523.249V724.076C523.249 736.389 533.204 746.345 545.517 746.345H645.333C657.647 746.345 667.602 736.389 667.602 724.076V463.533Z"
                        fill="white"
                    />
                    <path
                        d="M453.563 277H448.716H190.269C177.955 277 168 286.955 168 299.269V389.653C168 401.967 177.955 411.922 190.269 411.922H250.918H275.021V438.644V724.731C275.021 737.045 284.976 747 297.289 747H392.128C404.441 747 414.396 737.045 414.396 724.731V438.644V436.156V411.922H438.499H448.323H453.17C490.372 411.922 520.631 381.663 520.631 344.461C521.024 307.259 490.765 277 453.563 277Z"
                        fill="white"
                    />
                    <path
                        d="M667.735 463.533V645.35C672.713 646.529 677.821 647.446 683.061 648.232C690.397 649.28 697.994 649.935 705.592 650.066C705.985 650.066 706.378 650.066 706.902 650.066V505.45C685.026 504.009 667.735 485.801 667.735 463.533Z"
                        fill="url(#paint0_linear_408_225)"
                    />
                    <path
                        d="M709.781 277C606.822 277 523.249 360.573 523.249 463.533C523.249 552.084 584.946 626.225 667.733 645.35V463.533C667.733 440.347 686.596 421.484 709.781 421.484C732.967 421.484 751.83 440.347 751.83 463.533C751.83 483.051 738.6 499.425 720.523 504.14C717.117 505.057 713.449 505.581 709.781 505.581V650.066C713.449 650.066 716.986 649.935 720.523 649.804C818.505 644.171 896.314 562.956 896.314 463.533C896.445 360.573 812.872 277 709.781 277Z"
                        fill="white"
                    />
                    <path
                        d="M709.78 650.066V505.581C708.733 505.581 707.816 505.581 706.768 505.45V650.066C707.816 650.066 708.864 650.066 709.78 650.066Z"
                        fill="white"
                    />
                </g>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_408_225"
                    x1="709.844"
                    y1="556.827"
                    x2="667.753"
                    y2="556.827"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop
                        offset="0.9667"
                        stopColor="white"
                        stopOpacity="0.3233"
                    />
                    <stop offset="1" stopColor="white" stopOpacity="0.3" />
                </linearGradient>
                <clipPath id="clip0_408_225">
                    <rect
                        width="728.448"
                        height="470"
                        fill="white"
                        transform="translate(168 277)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default TokenPocket;
